import { ThemeProvider } from '@leshen/ui'
import React from 'react'
import GlobalStyles from './CustomStyles/GlobalStyles'
import siteTheme from 'gatsby-theme-brightspeed/theme'

const Wrapper = ({ children }) => (
  <ThemeProvider theme={siteTheme}>
    <GlobalStyles />
    {children}
  </ThemeProvider>
)

export default Wrapper
