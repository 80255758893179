/*
  BRAND LEVEL THEME
  If a project has multiple sites that have shared theming, those styles can be placed here.
  If a site needs to override a brand level style, that can be done by writing the desired override in the site level theme.js file.
*/
export default {
  colors: {
    primary: '#FFC800',
    secondary: '#FA4628',
    tertiary: '#FA783C',
    dark: '#404040',
    light: '#F9F9F9',
    warning: '#FF7928',
    error: '#FF3068',
    success: '#2DE6BC',
    background1: '#FFFAE6',
    background2: '#FFE480',
    background3: '#FED1C9',
    background4: '#FB745E',
    background5: '#FEDDCE',
    background6: '#FB9A6D',
  },
  gradients: {
    primary: 'linear-gradient(to right, #FFC800, #FA783C)',
    secondary: 'linear-gradient(to right, #FA783C, #FA4628)',
    tertiary: 'linear-gradient(to right, #FFC800, #FA4628)',
  },
  alternateBgColor: false,
  fonts: {
    headings: {
      family: '\'Poppins Extra Bold\', sans-serif',
    },
    body: {
      family: '\'Poppins\', sans-serif',
    },
    links: {
      primary: {
        color: '#000',
        activeColor: '#000',
        visitedColor: '#000',
        hoverColor: '#000',
      },
      secondary: {
        color: '#000',
        activeColor: '#000',
        visitedColor: '#000',
        hoverColor: '#000',
      },
      tertiary: {
        color: '#000',
        activeColor: '#000',
        visitedColor: '#000',
        hoverColor: '#000',
      },
      light: {
        color: '#000',
        activeColor: '#000',
        visitedColor: '#000',
        hoverColor: '#000',
      },
      dark: {
        color: '#fff',
        activeColor: '#fff',
        visitedColor: '#fff',
        hoverColor: '#fff',
      },
      black: {
        color: '#fff',
        activeColor: '#fff',
        visitedColor: '#fff',
        hoverColor: '#fff',
      },
      white: {
        color: '#000',
        activeColor: '#000',
        visitedColor: '#000',
        hoverColor: '#000',
      },
    },
  },
  components: {
    Price: {
      duration: {
        position: 'bottom', // top, bottom (default), center, underCents, underPrice
        weight: 'bold',
        offsetY: '-9px',
      },
    },
    Button: {
      rounded: 'max',
      variants: {
        primary: {
          color: 'black',
          hoverColor: 'black',
          hoverBg: '#ffb820',
          activeColor: 'black',
          activeBg: '#e8a231',
        },
        dark: {
          color: 'white',
        },
      },
    },
  },
  shadow: '0 0.3rem 1rem 0 rgba(0, 0, 0, 0.1)',
  radius: '0px',
}
